<template>
    <div>
        <div class="row flex-grow-1">
            <div class="col">
                <!-- TableList -->
                <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="detailInfo.userProps"
                    :keyField="'propCode'" :transCodes="transCodes" :mode="mode" :useMaxHeight="false"
                    :controls="controls" :toggleFields="['enabled']" :useFilter="false"
                    @row-click="handleListItemClick" @toggle-changed="handleItemToggle" @button-click="handleListButtonClick" />
                <!-- <PropsModalComponent ref="PropsModalComponent" :propType="'userType'" :mode="mode" :typeDetail="typeDetail" /> -->
            </div>
        </div>
        <b-modal ref="UserPropDetailModal" centered @hidden="closeUserPropModal">
            <template #modal-header>
                <h5>사용자 정의 속성 편집</h5>
            </template>
            <template #modal-footer centered>
                <button v-show="isEditMode" type="button" class="btn btn-lg btn-primary" @click="handleAddListItem">저장</button>
                <button type="button" class="btn btn-lg btn-secondary" @click="closeUserPropModal">닫기</button>
            </template>

            <UserPropDetailPanel ref="UserPropDetailPanel" :mode="mode" />
        </b-modal>
        <b-modal ref="AddUserPropsModal" centered @hidden="closeAddUserPropModal">
            <template #modal-header>
                <h5>사용자 정의 속성 추가</h5>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click="addUserProps">추가</button>
                <button class="btn btn-secondary" @click="closeAddUserPropModal">취소</button>
            </template>
            <UserPropAddPanel ref="UserPropAddPanel" :detailInfo="detailInfo" :typeDetail="typeDetail" />
        </b-modal>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
// import PropsModalComponent from "../modal/PropsModal.vue"
import UserPropAddPanel from "./UserPropAddPanel.vue"
import UserPropDetailPanel from "./UserPropDetailPanel.vue";

export default {
    components: {
        TableListComponent,
        // PropsModalComponent,
        UserPropAddPanel,
        UserPropDetailPanel,
    },
    props: [ 'detailInfo', 'mode', 'typeDetail' ],
    created() {
        console.log('EquipInfo UserPropsListPanel.created() called')
    },
    mounted() {
        console.log('EquipInfo UserPropsListPanel.mounted() called');
    },
    data() {
        return {
            controls: [
                {event: 'delete', label: '-', class: 'btn-danger'},
                {event: 'regist', label: '+', class: 'btn-warning'},
            ],
            transCodes: [
                {
                    field: 'dataSourceType',
                    codes: this.$store.state.commonCodes.dataSourceType,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['propCode', 'propName']
                },
                {
                    label: this.$t('속성명'),
                    field: 'propName',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['propCode', 'propName']
                },
                // {
                //     label: this.$t('관제 유형'),
                //     field: 'dataSourceType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('연결 관제점 주소'),
                    field: 'ptAddr',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                {
                    label: this.$t('관제점 ID'),
                    field: 'ptIdx',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center custom-ptIdx',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                // {
                //     label: this.$t('단위 유형'),
                //     field: 'unitType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('정의값'),
                    field: 'userValue',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                }
            ],
            inPropBuffer: null,

            tempUserProp: null,
            tempUserPropIndex: null,
            userProp: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },        
    },
    watch: {
        detailInfo() {
            console.log('WATCH UserPropListPanel detailInfo is changed:', this.detailInfo);
        }
    },
    methods: {
        async closeUserPropModal() {
            await this.$refs.UserPropDetailModal.hide();
            if(this.tempUserPropIndex !== null || undefined) this.tempUserPropIndex = null;
        },
        addUserProps() {
            const getSelect = this.$refs.UserPropAddPanel.selectProps();
            const filterProps = this.typeDetail.userProps.filter(prop => getSelect.some(select => select.propCode == prop.propCode))
            filterProps.forEach(item => {
                this.detailInfo.userProps.push({...item})
            })
            this.$refs.AddUserPropsModal.hide();
        },
        async deleteProps() {
            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if(!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if(!confirm.value) return;
                    checked.forEach(async () => {
                        this.detailInfo.userProps = this.detailInfo.userProps.filter(prop => {
                            return !checked.find(checkedProp => checkedProp.propCode == prop.propCode)
                        });
                    });
                } else {
                    this.alertNoti("삭제할 목록을 선택해주세요.")
                }
            } catch(e) {
                this.alertNoti("삭제에 실패하였습니다");
                console.error(e);
            }
        },
        closeAddUserPropModal() {
            this.$refs.AddUserPropsModal.hide();
        },
        async handleListButtonClick(event, options) {
            const that = this;

            console.log(`handleListButtonClick(${event}). options:`, options);

            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteProps();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            // if(toggle || check || !this.isEditMode) return;
            if (toggle || check) return;
            console.log(this.detailInfo.userProps);
            console.log('handleListItemClick. row:', row);
            console.log('handleListItemClick. index:', index);
            console.log("detailInfo.userPRops : ", this.detailInfo)

            this.tempUserPropIndex = index;
            let findUserProp = this.detailInfo.userProps.find(item => item.propCode == row.propCode)
            await this.$refs.UserPropDetailModal.show();
            await this.$refs.UserPropDetailPanel.setData(findUserProp);
        },
        async registNew() {
            const that = this;
            console.log('Regist New One :', that.inPropBuffer);
            // that.tempUserProp = null;
            
            that.$refs.AddUserPropsModal.show();
        },        
        handleAddListItem() {
            console.log('handleAddListItem : userProps: ', this.detailInfo.userProps);
            console.log("handleAddListItem this.tempUserPropIndex", this.tempUserPropIndex);

            const userProp = this.$refs.UserPropDetailPanel.getData();

            if(userProp) {
                console.log("handleAddListItem this.tempUserPropIndex", this.tempUserPropIndex);
                if(this.tempUserPropIndex !== null || undefined) {
                    console.log("edit Props")
                    this.$set(this.detailInfo.userProps, this.tempUserPropIndex, userProp);
                    this.tempUserPropIndex = null;
                } else return;
                // else {
                //     console.log("new Props")
                //     // Check Duplication (propCode, propName)
                //     let check = this.detailInfo.userProps.find(prop => prop.propCode === ioProp.propCode || prop.propName === ioProp.propName);
                //     if (check) {
                //         // TODO: Alert Message
                //         console.error('TODO: Alert Message');
                //         console.error(`In Property is already exists in userProps. propCode:${ioProp.propCode}, propName:${ioProp.propName}`);
                //     } else {
                //         this.$set(this.detailInfo.userProps, this.detailInfo.userProps.length, ioProp);
                //     }
                // }
            }

            console.log('handleAddListItem : userProps: ', this.detailInfo.userProps);

            this.$refs.UserPropDetailModal.hide();
        },
        handleItemToggle(key, field, status) {
            console.log("ioProp toggle change key : ", key)
            console.log("ioProp toggle change field : ", field)
            console.log("ioProp toggle change status : ", status)

            const that = this;
            const found = that.detailInfo.userProps.find(item => item.propCode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>