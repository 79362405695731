<template>
    <div>
        <form class="form-group row">
            <div class="col-md-12 mb-3">
                <span>설비 유형을 선택하여 설비를 등록합니다.</span>
            </div>
            <div class="col-md-12">
                <b-form-select v-model="equipType" :options="options" :disabled="equipType !== ''"></b-form-select>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components: {},
    props: [ 'mode' ],
    created() {
        if(this.$route.query.equipType) this.equipType = this.$route.query.equipType;
        else this.equipType = '';
    },
    mounted() {
    },
    data() {
        return {
            equipType: '',
        };
    },
    computed: {
        ...mapState({
            equipTypeList: state => state.equipTypeList
        }),
        isNewMode() {
            return this.mode === 'new';
        },
        options() {
            const options = this.equipTypeList.map(equip => {
                return {
                    value: equip.equipType,
                    text: `${equip.equipType}(${equip.equipTypeName})`
                };
            })
            console.log('options : ', options);

            options.unshift({ value: '', text: '설비 유형 선택' });

            // console.log('options : ', options);

            return options;
        },
    },
    watch: {},
    methods: {
        getEquipType() {
            return this.equipType;
        },
    },
}
</script>

<style scoped>

</style>