<template>
    <div>
        <!-- <div>{{ detailInfo.calcProps }}</div>
        <div>{{ typeDetail.calcProps }}</div> -->
        <!-- <div>{{ calcPropList }}</div> -->
        <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="calcPropList"
            :keyField="'propCode'" :transCodes="transCodes" :mode="'edit'" :useMaxHeight="false"
            :useFilter="false" />
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["detailInfo", "typeDetail"],
    components: {
        TableListComponent,
    },
    data() {
        return {
            calcPropList: null,
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('속성 코드명'),
                    field: 'propName',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('단위 유형'),
                    field: 'unitType',
                    type: 'text',
                },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                },
            ],
        }
    },
    created() { },
    mounted() {
        console.log("calc prop add modal mounted")
        this.setCalcPropList(this.detailInfo, this.typeDetail)
    },
    destroyed() {
        console.log("calc prop add modal destroyed")
    },
    methods: {
        setCalcPropList(detail, typeDetail) {
            if (detail.calcProps.length > 0) {
                if (typeDetail.calcProps.length > 0) {
                    console.log("calcProps not empty")
                    this.calcPropList = typeDetail.calcProps.filter(prop => !detail.calcProps.some(calc => prop.propCode == calc.propCode));
                }
            } else {
                this.calcPropList = typeDetail.calcProps;
            }
        },
        selectProps() {
            return this.$refs.TableListComponent.getCheckedRows();
        }
    }
}

</script>