<template>
    <div v-if="detailInfo" class="detail-container">
        <div class="detail-content">
            <div class="row">
                <div class="col-md-7">
                    <label for="ptAddr">연결된 관제점</label>
                    <input type="text" class="form-control" id="ptAddr" disabled :value="detailInfo.ptAddr ?? '-'">
                </div>
                <div class="col-md-2 p-0">
                    <label for="ptIdx">관제점 ID</label>
                    <input v-model="detailInfo.ptIdx" type="text" class="form-control" id="ptIdx" disabled>
                </div>
                <div class="col-md-3" style="display: flex; flex: 1; align-items: end; justify-content: end;">
                    <button v-b-toggle.ptSearchSideBar type="button" class="btn btn-primary" style="flex: 1;" :disabled="!isEditMode">관제점 선택</button>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6 mt-2">
                    <label for="propCode">r계측 속성</label>
                    <input v-model="detailInfo.propCode" type="text" class="form-control" id="propCode"
                        :disabled="!isEditMode">
                </div>
                <div class="form-group col-md-6 mt-2">
                    <label for="propName">계측 속성명</label>
                    <input v-model="detailInfo.propName" type="text" class="form-control" id="propName"
                        :disabled="!isEditMode">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="unitType">단위 유형</label>
                    <select v-model="detailInfo.unitType" id="unitType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(type, index) in $store.state.unitTypes" :key="index" :value="type.value">{{
                            type.text }}</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="unit">단위</label>
                    <select v-model="detailInfo.unit" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="(code) in $store.state.unitCodes[detailInfo.unitType]" :key="code.value"
                            :value="code.value" id="unit">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <!-- <div v-if="searchValue" class="col-md-6 detail-content" style="border-left: solid #eee 1px;">
            <SearchComponent 
                :data="detailInfo" :tableMaxHeight="'36vh'" :optionTableHeight="'28vh'" 
                @select-point="selectPoint" @close="search" />
        </div> -->
        <b-sidebar v-model="sideBar" ref="ptSearchSideBar" id="ptSearchSideBar" bg-variant="white"
            backdrop-variant="secondary" width="50vw" @hidden="closeSidebar" no-header shadow right backdrop>
            <div v-if="sideBar" style="padding: 1rem; height: 100%">
                <SearchComponent :tableMaxHeight="'75vh'" :optionTableHeight="'67vh'" @select-point="selectPoint"
                    @close="closeSidebar" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import SearchComponent from '@src/views/component/v2.1/PointSearch/Search.vue';

export default {
    components: {
        SearchComponent
    },
    props: ["mode"],
    data() {
        return {
            detailInfo: null,
            sideBar: false,
        }
    },
    watch: {},
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    created() { },
    mounted() { },
    destroyed() { },
    methods: {
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.ptSearchSideBar.hide();
            this.sideBar = false;
        },
        initData() {
            return {
                propCode: null,
                propName: null,
                dataSourceType: "Meas",
                ptIdx: null,
                userValue: null,
                enabled: "Y",
                ptAddr: null,
                unitType: null,
                unit: null,
            }
        },
        setData(data) {
            if (data) {
                this.detailInfo = data;
            } else {
                this.detailInfo = this.initData();
            }
        },
        getData() {
            return this.detailInfo;
        },
        async selectPoint(data) {
            console.log(data);
            if (data) {
                this.detailInfo.ptIdx = data.ptIdx ? data.ptIdx : '-';
                this.detailInfo.ptAddr = data.ptAddr ? data.ptAddr : '-';
                this.detailInfo.unitType = data.unitLargeCode ? data.unitLargeCode : null;
                this.detailInfo.unit = (data.unitSmallCode !== "" && data.unitSmallCode) ? data.unitSmallCode : null;
            }
            await this.$refs.ptSearchSideBar.hide();
        },
        async setInputPtIdx() {
            try {
                let result = await backEndApi.pointInfo.getPointDetailNew(this.detailInfo.ptIdx);
                console.log(result)
                if (result.status === 200) {
                    this.detailInfo.ptAddr = result.data.ptAddr;
                }
            } catch (e) {
                this.alertNoti("입력한 관제점 번호와 일치하는 내용이 없습니다.")
                console.error(e)
            }
        }
    },
}

</script>

<style scoped>
.detail-container {
    height: 100%;
    /* height: 400px; */
    /* width: 100%; */
    /* border-radius: 8px; */
    /* border: solid #eee 1px; */
    /* background-color: white; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 100%;
    width: 100%;
    /* border-radius: 0px 0px 8px 8px; */
    /* border: solid #eee 1px; */
    /* padding: 1rem 1rem; */
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}

.btn-sm {
    height: 1.4rem !important;
    line-height: 0.9 !important;
}
</style>