<template>
    <div>
        <div class="row flex-grow-0 mb-2">
            <div class="col">
                <!-- TableList -->
                <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="detailInfo.ioProps"
                    :keyField="'propCode'" :transCodes="transCodes" :mode="mode" :useMaxHeight="false" :fixedHeader="false"
                    :toggleFields="['enabled']" :useFilter="false" :controls="controls" @row-click="handleListItemClick"
                    @toggle-changed="handleItemToggle" @button-click="handleListButtonClick" />
        
                <b-modal ref="IoPropDetailModal" centered @hidden="closeIoPropModal">
                    <template #modal-header>
                        <h5>계측 속성</h5>
                    </template>
                    <template #modal-footer centered>
                        <button v-show="isEditMode" type="button" class="btn btn-lg btn-primary" @click="handleAddListItem">저장</button>
                        <button type="button" class="btn btn-lg btn-secondary" @click="closeIoPropModal">닫기</button>
                    </template>
        
                    <IoPropDetailPanel ref="IoPropDetailPanel" :mode="mode" />
                </b-modal>

            </div>
        </div>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
// import PropsModalComponent from "../modal/PropsModal.vue"
import IoPropDetailPanel from "./IoPropDetailPanel.vue"

export default {
    components: {
        TableListComponent,
        // PropsModalComponent,
        IoPropDetailPanel,
    },
    props: ['detailInfo', 'mode'],
    created() {
        console.log('EquipInfo IoPropListPanel.created() called')
    },
    mounted() {
        console.log('EquipInfo IoPropListPanel.mounted() called')
    },
    data() {
        return {
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger' },
                { event: 'regist', label: '+', class: 'btn-warning' },
            ],
            transCodes: [
                {
                    field: 'dataSourceType',
                    codes: this.$store.state.commonCodes.dataSourceType,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                    // compositionFields: ['propName', 'propCode']
                },
                {
                    label: this.$t('속성명'),
                    field: 'propName',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                // {
                //     label: this.$t('관제 유형'),
                //     field: 'dataSourceType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('연결 관제점 주소'),
                    field: 'ptAddr',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                {
                    label: this.$t('관제점 ID'),
                    field: 'ptIdx',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center custom-ptIdx',
                    // compositionFields: ['ptAddr', 'ptIdx']
                    // muntiFields: ['ptAddr', 'ptIdx']
                },
                // {
                //     label: this.$t('단위 유형'),
                //     field: 'unitType',
                //     type: 'text',
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('계측값'),
                    field: 'ptVal',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                }
            ],
            // tempIoProp: null,
            tempIoPropIndex: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    watch: {
        detailInfo() {
            console.log('WATCH EquipInfo IoPropListPanel detailInfo is changed:', this.detailInfo);
        },
        // "detailInfo.ioProps": {
        //     immediate: true,
        //     handler(newVal) {
        //         console.log("WATCH EquipInfo IoPropListPanel detailInfo.ioProps is changed:", newVal);
        //     }
        // },
        "detailInfo.ioProps": function(newVal) {
            console.log("WATCH EquipInfo IoPropListPanel detailInfo.ioProps is changed:", newVal);
        },
    },
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;

            console.log(`handleListButtonClick(${event}). options:`, options);

            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            if (toggle || check) return;
            console.log('handleListItemClick. row:', row);
            console.log('handleListItemClick. index:', index);
            console.log("detailInfo.ioProps : ", this.detailInfo)

            this.tempIoPropIndex = index;
            let findIoProp = this.detailInfo.ioProps.find(item => item.propCode == row.propCode)
            await this.$refs.IoPropDetailModal.show();
            await this.$refs.IoPropDetailPanel.setData(findIoProp);
        },
        async closeIoPropModal() {
            await this.$refs.IoPropDetailModal.hide();
            if(this.tempIoPropIndex !== null || undefined) this.tempIoPropIndex = null;
        },
        handleAddListItem() {
            console.log('handleAddListItem : ioProps: ', this.detailInfo.ioProps);
            console.log("handleAddListItem this.tempIoPropIndex", this.tempIoPropIndex);

            const ioProp = this.$refs.IoPropDetailPanel.getData();

            if(ioProp) {
                console.log("handleAddListItem this.tempIoPropIndex", this.tempIoPropIndex);
                if(this.tempIoPropIndex !== null || undefined) {
                    console.log("edit Props")
                    this.$set(this.detailInfo.ioProps, this.tempIoPropIndex, ioProp);
                    this.tempIoPropIndex = null;
                } else {
                    console.log("new Props")
                    // Check Duplication (propCode, propName)
                    let check = this.detailInfo.ioProps.find(prop => prop.propCode === ioProp.propCode || prop.propName === ioProp.propName);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in ioProps. propCode:${ioProp.propCode}, propName:${ioProp.propName}`);
                    } else {
                        this.$set(this.detailInfo.ioProps, this.detailInfo.ioProps.length, ioProp);
                    }
                }
            }

            console.log('handleAddListItem : ioProps: ', this.detailInfo.ioProps);

            this.$refs.IoPropDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async (item) => {
                        console.log('deleteChecked. equipType :', item.propCode);
                        // await backEndApi.propCodes.deleteEquipType(item.propCode);
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.ioProps = that.detailInfo.ioProps.filter(prop => {
                            return !checked.find(checkedProp => checkedProp.propCode === prop.propCode);
                        })
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            const that = this;

            that.$refs.IoPropDetailModal.show();
            that.$nextTick(() => {
                that.$refs.IoPropDetailPanel.setData(null);
            })
        },
        handleItemToggle(key, field, status) {
            console.log("ioProp toggle change key : ", key)
            console.log("ioProp toggle change field : ", field)
            console.log("ioProp toggle change status : ", status)

            const that = this;
            const found = that.detailInfo.ioProps.find(item => item.propCode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>